/* eslint-disable no-console */

import { register } from 'register-service-worker';
import Vue from 'vue';
import debug from '@/debug';

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      debug(
        'App is being served from cache by a service worker.\n'
        + 'For more details, visit https://goo.gl/AFskqB',
      );
    },
    registered() {
      debug('Service worker has been registered.');
    },
    cached() {
      debug('Content has been cached for offline use.');
    },
    updatefound() {
      Vue.$toast('info', 'downloading update');
    },
    updated() {
      caches.keys().then((names) => {
        Object.keys(names).forEach(name => caches.delete(name));
      });
      if (/\/beta/.test(window.location.pathname)) {
        window.location.reload();
      } else {
        Vue.$toast('info', 'application updated, please refresh.', {
          sticky: true,
          onClose() {
            window.location.reload();
          },
        });
      }
    },
    offline() {
      debug('No internet connection found. App is running in offline mode.');
    },
    error(error) {
      console.error('Error during service worker registration:', error);
    },
  });
}
