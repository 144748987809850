<template>
  <div class="toasts">
    <button
      v-for="t in stickyToasts"
      :class="t.level"
      @click="() => closeToast(t)"
      :key="`toast-${t.uuid}`">
      {{ t.message }}
    </button>
    <button
      v-for="t in liveToasts"
      :class="t.level"
      @click="() => closeToast(t)"
      :key="`toast-${t.uuid}`">
      {{ t.message }}
    </button>
  </div>
</template>

<script>

let uuid = 0;

export default {
  name: 'toast-notification',
  props: {
    toasts: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      stickyToasts: [],
      liveToasts: [],
    };
  },
  watch: {
    toasts() {
      while (this.toasts.length > 0) {
        const toast = this.toasts.shift();
        if (!toast || !toast.message) return;
        if (!toast.level) toast.level = 'info';
        toast.uuid = uuid++;
        if (toast.options && toast.options.sticky) {
          this.stickyToasts.push(toast);
        } else {
          this.liveToasts.push(toast);
          const tt = this.liveToasts;
          setTimeout(() => { if (tt[0] === toast) tt.shift(); }, 5000);
        }
      }
    },
  },
  methods: {
    closeToast(toast) {
      const toasts = toast.options && toast.options.sticky ? this.stickyToasts : this.liveToasts;
      const idx = toasts.findIndex(t => t === toast);
      toasts.splice(idx, 1);
      if (toast.options && toast.options.onClose) {
        toast.options.onClose();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/_colors.scss';

.toasts {
  position: fixed;
  top: 5px;
  left: 0;
  right: 0;
  max-height: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: center;
  z-index: 999;
}

.toasts button {
  padding: 0.5em 1em;
  border-radius: 6px;
  margin: 2px;
}

.error {
  background: $color-error;
  border: 3px double scale-color($color-error, $saturation: -20%, $lightness: +70%);
  color: $color-light;
}
.warn, .warning {
  background: $color-warning;
  border: 1px solid scale-color($color-warning, $saturation: -50%, $lightness: +70%);
  color: $color-dark;
}
.success, .ok {
  background: $color-success;
  border: 1px solid scale-color($color-success, $saturation: -50%, $lightness: +70%);
  color: $color-light;
}
.info {
  background: $color-info;
  border: 1px solid scale-color($color-info, $saturation: -50%, $lightness: +70%);
  color: $color-dark;
}
</style>
