var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "toasts" },
    [
      _vm._l(_vm.stickyToasts, function(t) {
        return _c(
          "button",
          {
            key: "toast-" + t.uuid,
            class: t.level,
            on: {
              click: function() {
                return _vm.closeToast(t)
              }
            }
          },
          [_vm._v(" " + _vm._s(t.message) + " ")]
        )
      }),
      _vm._l(_vm.liveToasts, function(t) {
        return _c(
          "button",
          {
            key: "toast-" + t.uuid,
            class: t.level,
            on: {
              click: function() {
                return _vm.closeToast(t)
              }
            }
          },
          [_vm._v(" " + _vm._s(t.message) + " ")]
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }